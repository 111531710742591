<template>
  <div>
    <div class="text-center mb-2 mt-1">
      <el-radio-group v-model="field.is_global_variable" @change="setData">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="38">
        <el-col :span="12">
          <div class="form-group">
            <div v-if="errors" class="error-message">
          {{ errorMessage }}
          </div>
            <el-form-item label="Options">
              <el-select
                v-model="field.options"
                placeholder="Enter options"
                :clearable="true"
                size="mini"
                :allow-create="true"
                :default-first-option="true"
                :filterable="true"
                multiple
                collapse-tags
                @change="validateOptions"
              >
               <el-option
                  v-for="(opt, index) of field.options"
                  :key="index"
                  :label="opt"
                  :value="opt"                 
                >
                </el-option>
              </el-select> 
              <i class="el-icon-edit ml-05"  @click="openOptions"></i>          
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="mt-2">
           <el-checkbox v-model="field.add_global">Add to Global Variable</el-checkbox>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />   
        </el-col>
        <el-col :span="10">
       
          <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  filterable
                  :label="globalVariable.label"
                  :value="globalVariable._id"
                >
                  <span style="float: left">{{ globalVariable.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    globalVariable.input_type
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" />
    </el-form>
    <dialog-component
        :visible="optionsDialogVisible"
          :title="'Edit options'"
          :destroy-on-close="true"
          :containerWidth="'35%'"
          :containerMaxHeight="'65%'"
          @before-close="optionsDialogVisibleClose"
          center>
        <div class="tableScroll">
          <el-table  
           border
          :data="dialogOptions"
          @cell-mouse-enter="editoptionsData" 
          @cell-mouse-leave="resetoptionsData" 
          >
          <el-table-column  label="Options">
            <template slot-scope="scope">
              <div v-if="currentActiveRowIndex == scope.$index">
                <el-input size="mini" v-model="dialogOptions[currentActiveRowIndex]"></el-input>
              </div>
              <div v-else>
                <p style="font-size: 14px" slot="reference">
                {{ scope.row }}
              </p>  
            </div>
            </template>  
          </el-table-column>
          </el-table>
        </div>
        <el-collapse>
        <el-collapse-item>
          <template slot="title">Drag fields to change the column order</template>
            <div>
              <el-scrollbar>
                <table style="z-index: 1000; border: 10px; margin: 5px 0px">
                  <draggable v-model="dialogOptions" @input="updateFieldOptions" class="d-flex">
                    <tr v-for="(column, index) in dialogOptions" :key="index">
                      <td>
                        <el-tag type="info">{{ column }}</el-tag>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </el-scrollbar>
            </div>
        </el-collapse-item>
      </el-collapse>
        <span slot="footer">
          <el-row>
             <el-button style="background-color: var(--primary-color);float:right; color: #ffffff" class="type-2" 
              @click="saveData">Save</el-button>
          </el-row>
          </span>
    </dialog-component>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";
//import { mapGetters } from "vuex";
import { fetchGlobalVariables } from "@/repo/globalVariables"
import draggable from "vuedraggable";

export default {
  name: "templates-formComponents-Select",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes"),
    draggable,
  },
  props: ["field" , "isView"],
  computed: {
   // ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  data() {
    return {
      validations: [],
      getAllGlobalVariables: null,
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      errors: false,
      errorMessage: "",
      initialValidation:false,
      optionsDialogVisible:false,
      currentActiveRowIndex: -1,
      dialogOptions: [],
      iconPickerdialogVisible: false,
    
    };
  },
  mounted() {
    this.fetchGlobalVaribales();
    this.initialValidation = true;
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
    optionsDialogVisibleClose(){
      this.optionsDialogVisible=false;
    },
    openOptions() {
      if (this.isView) {
        return;
      }
      this.optionsDialogVisible = true;
    },
    setData() {
      if (!this.field.is_global_variable) {
        // this.field.options = [];
        this.field.global_variable_id = null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_types: ["SELECT"],
      };
      this.getAllGlobalVariables = await fetchGlobalVariables(params);
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
    validateOptions() {
      const options = this.field.options.map(opt => opt.trim());
      const normalizedOptions = options.map(opt => opt.toLowerCase());

      // Check if options array is empty
      if (options.length === 0 && this.initialValidation) {
        this.errors = true;
        this.errorMessage = " ";
        return;
      }

      // Check if any option consists only of spaces
      const hasInvalidOptions = options.some((opt) => opt.trim() === "");
      if (hasInvalidOptions) {
        this.errors = true;
        this.errorMessage = "An option should not be only of spaces,so please remove it.";
        return;
      }
      // Check for case-insensitive duplicates
      const uniqueNormalizedOptions = [...new Set(normalizedOptions)];
      if (uniqueNormalizedOptions.length !== normalizedOptions.length) {
        this.errors = true;
        this.errorMessage = "Duplicate options (case-insensitive) were removed.";
      }
      // Filter out duplicate options and keep the first occurrence
      const uniqueOptions = options.filter((opt, index) => {
        return normalizedOptions.indexOf(opt.toLowerCase()) === index;
      });
      this.field.options = uniqueOptions;

      // Valid options
      this.errors = false;  
      this.errorMessage = "";
    },
    editoptionsData(row) {
      this.currentActiveRowIndex = this.field.options.indexOf(row);
    },
    resetoptionsData() {
      // Normalize options to lowercase and filter out duplicates
      const normalizedOptions = this.dialogOptions.map(option => option.trim().toLowerCase());
      const uniqueOptions = this.dialogOptions.filter((option, index) => {
        return normalizedOptions.indexOf(option.trim().toLowerCase()) === index;
      });
      this.dialogOptions = uniqueOptions;
      this.field.options = uniqueOptions.slice();
      this.currentActiveRowIndex = -1;
    },
    updateFieldOptions() {
      this.field.options = [...this.dialogOptions];
    },
    saveData(){
      this.updateFieldOptions();
      this.optionsDialogVisible = false;
    }
   
  },
  watch: {
    optionsDialogVisible(newVal) {
      if (newVal) {
        this.dialogOptions = this.field.options.slice();
      }
    },
  },
}
</script> 

<style lang="scss">
.error-message {
  color: red;
}
.tableScroll {
  scrollbar-width: thin;
  height: 50vh;
  overflow-y: auto;
  }
</style>